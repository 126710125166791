
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$md-mcgpalette0: (
    50 : #eefbfd,
    100 : #d4f6f9,
    200 : #b7f0f5,
    300 : #9aeaf1,
    400 : #84e6ee,
    500 : #6ee1eb,
    600 : #66dde9,
    700 : #5bd9e5,
    800 : #51d5e2,
    900 : #3fcddd,
    A100 : #ffffff,
    A200 : #f9feff,
    A400 : #c6f9ff,
    A700 : #adf6ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$teggium-primary: mat.define-palette($md-mcgpalette0);
$teggium-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$teggium-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$teggium-theme: mat.define-light-theme((
  color: (
    primary: $teggium-primary,
    accent: $teggium-accent,
    warn: $teggium-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($teggium-theme);

/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: 'Instrument sans';
    src: url(./assets/fonts/InstrumentSans-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: 'Instrument sans';
    src: url(./assets/fonts/InstrumentSans-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: 'Instrument sans';
    src: url(./assets/fonts/InstrumentSans-Bold.ttf);
    font-weight: 700;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    font-family:'Instrument sans';

    .mat-mdc-tab.mdc-tab{
        // width: 33.33%;
        // flex: 1;
    }


    .mat-mdc-tab .mdc-tab__text-label{
        font-family:'Instrument sans';
        font-size: 16px;
        font-weight: 400; 
        color: #0F0F0F;
    }


    .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label{
        font-weight: bolder;
        color: #0F0F0F;
    }

    .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline{
        border-color: #E1E1E1;
    }

    .mdc-tab-indicator__content{
        opacity: inherit;
    }

    .mdc-tab-indicator--active .mdc-tab-indicator__content{
        border-color: #6EE1EB !important;
    }

}
html, body { height: 100%; }
body { margin: 0; font-family: 'Instrument sans' }

.title-bold{
    font-size: 40px;
    font-weight: bold;
}

.subtitle-normal{
     font-weight: normal;
    font-size: 18px;
}

.subtitle-parrafo-blog{
    font-family: 'Instrument sans';
    font-size: 24px;
    margin: 10px 0px;
    font-weight: bold;
}

.parrafo-normal{
    font-family: 'Instrument sans';
    margin: 20px 0px;
    font-weight: normal;
   font-size: 16px;
   line-height: 33.35px
}

.button-size{
    font-family: 'Instrument sans';
    font-size: 16px;
    font-weight: 700;
    padding: 14px 16px 14px 16px;
    border-radius: 8px;
    background-color: #6EE1EB;
    border: none;
    transition:all 0.3s;
    cursor: pointer;
    color: #0F0F0F;
    -webkit-text-fill-color: #0F0F0F;
}
.button-size:hover{
    font-family: 'Instrument sans';
    font-size: 16px;
    font-weight: 700;
    padding: 14px 16px 14px 16px;
    border-radius: 8px;
    background-color: #E1E1E1;
    border: none;
}

.button-size-second{
    font-family: 'Instrument sans';
    font-size: 16px;
    font-weight: 700;
    padding: 14px 16px 14px 16px;
    border-radius: 8px;
    background-color: #6EE1EB;
    border: none;
    transition:all 0.3s;
    cursor: pointer;
    color: #0F0F0F;
    -webkit-text-fill-color: #0F0F0F;
}

.button-size-second:hover{
    font-family: 'Instrument sans';
    font-size: 16px;
    font-weight: 700;
    padding: 14px 16px 14px 16px;
    border-radius: 8px;
    background-color:  #0F0F0F;
    border: none;
    color: white !important;
    -webkit-text-fill-color: white;
    transition:all 0.3s ;
}

@media screen and (max-width:1024px){
    .title-bold{
                font-size: 36px;
            }
}

@media screen and (max-width:768px) {
    .title-bold{
        font-size: 32px;
    }
}

@media screen and (max-width:576px) {
    .title-bold{
        font-size: 30px;
    }

    .subtitle-normal{
        font-size: 16px ;
    }
}
@media screen and (max-width:340px) {
    .title-bold{
        font-size: 20px;
    }
}

@media screen and (max-height:576px) {
    .subtitle-normal{
        font-size: 14px ;
    }
}

@media screen and (max-height:400px) {
    .title-bold{
        font-size: 25px;
    } 

    
}



@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

// @import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.css");
/* Importing Bootstrap SCSS file. */
// @import 'bootstrap/scss/bootstrap';
